import { Drawer as _Drawer, DrawerProps } from "@mantine/core";

interface IDrawerProps extends DrawerProps {}
export function Drawer({ title, children, classNames, ...rest }: IDrawerProps) {
  return (
    <_Drawer
      size="xl"
      zIndex={10}
      position="right"
      title={<span className="font-semibold text-primary-90">{title}</span>}
      classNames={{
        header: "border-b px-4 py-3 mb-4 sticky z-10 top-0 bg-white",
        drawer: "flex flex-col h-full pb-4 overflow-auto",
        overlay: "bg-[rgba(0,0,0,.25)]",
        body: "flex-1 overflow-auto",
        ...classNames,
      }}
      withCloseButton
      {...rest}
    >
      {children}
    </_Drawer>
  );
}
