import {
  Group,
  Button,
  ButtonProps,
  createPolymorphicComponent,
  GroupProps,
} from "@mantine/core";
import { ComponentPropsWithoutRef, ReactNode } from "react";

interface FormButtons extends GroupProps {
  submitText?: ReactNode;
  cancelText?: ReactNode;
  submitButtonProps?: ButtonProps & ComponentPropsWithoutRef<"button">;
  cancelButtonProps?: ButtonProps & ComponentPropsWithoutRef<"button">;
  hideCancel?: boolean;
}

export function _FormButtons({
  submitText = "",
  cancelText = "",
  submitButtonProps,
  cancelButtonProps,
  hideCancel = false,
  ...props
}: FormButtons) {
  return (
    <Group position={hideCancel ? "right" : "apart"} {...props}>
      {!hideCancel && (
        <Button
          type="button"
          className="bg-gray-200 text-primary-80 hover:text-primary-90 hover:bg-primary-10"
          {...cancelButtonProps}
        >
          {cancelText || "Cancel"}
        </Button>
      )}
      <Button
        className="font-semibold bg-accent-90 hover:bg-accent-80"
        {...submitButtonProps}
        type="submit"
      >
        {submitText || "Proceed"}
      </Button>
    </Group>
  );
}

_FormButtons.displayName = "FormButtons";
export const FormButtons = createPolymorphicComponent<"button", FormButtons>(
  _FormButtons
);
