import { queryClient } from "@/provider";
import { useErrorNotification } from "./useErrorNotification";
import { useQuery } from "react-query";

type WebSocketProps = {
  pathname: string;
  options?: object;
  key: string[];
  queryFn?: <U>(x) => U;
};

export function useWebSocket<T, U = T>({
  pathname,
  options = { enabled: true },
  queryFn = <T>(e: T) => e,
  key = [],
}: WebSocketProps) {
  const url = `${process.env.NEXT_PUBLIC_WEBSOCKET_URL}${pathname}`;
  const keys = ["ws"].concat(key);

  async function queryPromise() {
    const wss = new WebSocket(url);
    wss.onopen = () => {
      if (process.env.NODE_ENV === "development") {
        console.info(`open WS: ${pathname}`);
      }
    };

    return await new Promise<U>((resolve, reject) => {
      wss.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data) as T;
          resolve(queryFn(data));
        } catch (e: any) {
          reject(e);
        }
      };
      wss.onclose = async () => {
        if (process.env.NODE_ENV === "development") {
          console.info(`closed WS: ${pathname}`);
        }
        queryClient.removeQueries(keys);
      };
    });
  }

  const query = useQuery<U>(keys, queryPromise, {
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });
  useErrorNotification(query.isError, `fetching ${pathname}`);
  return query;
}
