import { useMemo } from "react";
import { capitalize } from "lodash";

import { BoardsDataType, BoardsType } from "@/sample/queries";
import { useCustomQuery, queryVars } from ".";

type BoardField = keyof BoardsType["data"][number];

type UseGetBoards<
  T extends BoardField,
  U extends `boards${Capitalize<T>}` = `boards${Capitalize<T>}`
> = {
  [key in U]: Record<string, BoardsDataType>;
} & {
  boardsData: BoardsType;
  boardsLoading: boolean;
  boardsOptions: Array<{ label: string; value: string }>;
};

export function useGetBoards<K extends BoardField = "pk">(
  value: K = "pk" as K
) {
  const { query, keys } = queryVars;
  const { data: boardsData, isLoading: boardsLoading } =
    useCustomQuery<BoardsType>(keys.boards, query.boards);

  const boardsOptions = useMemo(() => {
    return (
      boardsData?.data?.map((board) => ({
        label: board.name,
        value: `${board[value]}`,
      })) ?? []
    );
  }, [boardsLoading]);

  const boards: Record<string, BoardsDataType> = useMemo(() => {
    const boards = boardsData?.data?.map((board) => [board[value], board]);
    return boards ? Object.fromEntries(boards) : {};
  }, [boardsLoading]);

  return {
    boardsData,
    boardsLoading,
    boardsOptions,
    [`boards${capitalize(value)}`]: boards,
  } as UseGetBoards<K>;
}
