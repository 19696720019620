import { MutableRefObject, useEffect, useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import { QUERIES } from "@/utils/config";
import { useURLSearchQuery } from "./useURLSearchQuery";
import { queryClient } from "@/provider";

const {
  urls: { query, mutations },
  keys,
} = QUERIES;

export interface QueryVarsType {
  query: typeof query;
  keys: typeof keys;
  mutations: typeof mutations;
}

export const queryVars: QueryVarsType = { query, keys, mutations };

export interface QueryResult<D extends "array" | "object" = "array"> {
  message?: string;
  responseCode?: string;
  count?: number;
  next?: string | null;
  previous?: string | null;
  data?: D extends "array" ? Array<Record<string, any>> : Record<string, any>;
}

type Serial = (e: number) => number;
export type DataType<D> = D[Extract<keyof D, "data">];
export type HookArgs = {
  url: string;
  keys: string | any[];
  params: Record<string, any> & {
    page?: number;
    page_size?: string;
  };
};

interface UseIncrementalQuery<D, T> {
  hookArgs: HookArgs;
  dataFilterer: (data: DataType<D>, serializer: Serial) => Array<T>;
  dependencies?: Array<any>;
  pageIndex: MutableRefObject<number>;
}

export function useIncrementalQuery<D extends QueryResult<any>, T>({
  dataFilterer,
  dependencies = [],
  hookArgs: { params, keys, url },
  pageIndex,
}: UseIncrementalQuery<D, T>) {
  const serializer = (index) => {
    const page = pageIndex.current - 1;
    const size = +(params?.page_size ?? 10);
    return page * size + ++index;
  };

  const [queryKeys, urlQuery] = useURLSearchQuery({ params, keys, url });

  const options = {
    enabled: !!params?.page_size,
    refetchOnWindowFocus: false,
    optimisticResults: false,
    cacheTime: 0,
  };

  useEffect(() => {
    pageIndex.current = 1;
  }, [params?.page_size, params?.queries, params?.q]);

  useEffect(() => {
    queryClient.resetQueries(queryKeys);
  }, [params?.queries]);

  const { data: fetchedData, ...props } = useInfiniteQuery<D>(
    queryKeys,
    urlQuery,
    options
  );

  const { isFetching } = props;

  const data = useMemo(() => {
    const current = fetchedData?.pages?.at(-1)?.data as DataType<D>;
    return dataFilterer(current ?? [], serializer) ?? [];
  }, [isFetching, ...dependencies]);

  return {
    data,
    fetchedData,
    ...props,
  };
}
