import { showNotification } from "@mantine/notifications";
import { useHandleError } from ".";

type Callback = (response?: any) => void;

export function useHandleSuccess(
  callback: Callback,
  hideNotification: boolean = false
) {
  const handleError = useHandleError();

  return function (response) {
    if (response?.responseCode === "100") {
      if (hideNotification) callback?.(response);
      else {
        showNotification({
          autoClose: 5_000,
          title: "Operation successful",
          message: response?.message,
          color: "green",
        });
      }
    } else handleError(response);

    callback?.(response);
  };
}
