import { BoardsType } from "@/sample/queries";
import { useMemo } from "react";
import { useCustomQuery, queryVars } from ".";

export function useGetBoardList() {
  const { query, keys } = queryVars;

  const { data: boardListData, isLoading: boardListLoading } =
    useCustomQuery<BoardsType>(keys.boards, query.boards);

  const boardListOptions = useMemo(() => {
    return (
      boardListData?.data?.map((board) => ({
        label: board.name,
        value: `${board.code}`,
      })) ?? []
    );
  }, [boardListLoading]);

  return {
    boardListData,
    boardListLoading,
    boardListOptions,
  };
}
