import { deleteCookie, getCookie } from "cookies-next";
import axios, { AxiosError } from "axios";
import qs from "query-string";

export const authAxios = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  // headers: {
  //   "CF-Access-Client-Secret":
  //     process.env.NEXT_PUBLIC_CF_ACCESS_CLIENT_SECRET ?? "",
  //   "CF-Access-Client-Id": process.env.NEXT_PUBLIC_CF_ACCESS_CLIENT_ID ?? "",
  // },
  paramsSerializer: (params) => {
    return qs.stringify(params, {
      skipNull: true,
      skipEmptyString: true,
      arrayFormat: "comma",
    });
  },
});

authAxios.interceptors.request.use((config) => {
  const token = getCookie("ecn.client.auth.token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

authAxios.interceptors.response.use(
  (res) => res,
  (error: AxiosError) => {
    const { response } = error;

    if (response?.status === 401) {
      deleteCookie("ecn.client.auth.token");
      location.reload();
    }

    return Promise.reject(response);
  }
);

export async function authGetter(url: string) {
  const response = await authAxios.get(url).then(({ data }) => data);
  return response;
}

export async function authPoster(url: string, data) {
  const response = await authAxios.post(url, data).then(({ data }) => data);
  return response;
}

export async function authDeleter(url: string, data = {}) {
  const response = await authAxios.delete(url, data).then(({ data }) => data);
  return response;
}

export async function authPutter(url: string, data) {
  const response = await authAxios.put(url, data).then(({ data }) => data);
  return response;
}
