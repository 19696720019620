import { toURLSearchParams } from "./lib";

function createUrl(path: string, query: Record<string, string> = {}) {
  for (const key in query) {
    if (query[key] === undefined) {
      delete query[key];
    }
  }
  const searchParams = new URLSearchParams(query);
  return `${path}?${searchParams.toString()}`;
}

export type CashCoverProps = {
  cid?: string;
  oms_provider__oms_code?: string;
  currency__code?: string;
  account_cover_type?: "otc_cash_cover" | "stock_cover";
};

export const QUERIES = {
  urls: {
    query: {
      accessibleBoards: "accessible-board",
      activityStream: "activity-stream",
      authenticate: "authenticate",
      authenticateOtp: "authenticate/otp",
      balanceSheet: (fiscal_year) => `balance-sheet?fy=${fiscal_year}`,
      bankList: "bank-list",
      boardList: "board-list",
      boards: "boards",
      buyOrders: "open-buy-trades",
      cashAdvance: "cash-advance/list",
      cashAdvancePosting: (id) => `cash-advance/${id}/posting`,
      cashCoverList: (props: CashCoverProps) =>
        createUrl("client-accounts-cover-report", props),
      cashCoverAggregate: (props: CashCoverProps) =>
        createUrl("client-accounts-cover-report-aggregate", props),
      cashCoverLog: (cid) => `cash-cover-log?client__cid=${cid}`,
      chartOfAccounts: "chart-of-accounts",
      chartOfAccountsDetails: (code) => `accounts-transaction-items/${code}`,
      chartOfAccountsHistory: (code) => `chart-of-accounts/${code}/detail`,
      chartOfAccountsSubAccount: (id) => `chart-of-accounts/${id}/sub-accounts`,
      checkAccountLock: "check-account-lock",
      ciaToCsdRequestList: "cia-to-csd-request-list",
      clientBankInfo: (cid) => `bank-info/${cid}`,
      clientCashAdvance: (cid) => `cash-advance/list?client__cid=${cid}`,
      clientDetail: (cid) => `client-detail/${cid}`,
      clientExport: "client-export?new=True",
      clientFundList: (cid, oms_code) =>
        `client-fund/list?client__cid=${cid}&oms_provider__oms_code=${oms_code}`,
      clientFundingLog: "client-fund/list",
      clientPermissions: (cid) => `client-permissions/${cid}`,
      clientPositions: "client-positions",
      clientPortfolio: (cid) => `client-portfolio?cid=${cid}`,
      clientPortfolioHistory: (cid) =>
        `client-portfolio-history?identifier=${cid}`,
      clientWallet: (cid) => `client-wallet?client__cid=${cid}`,
      clientSuspended: (cid) => `client-suspend/${cid}`,
      closedTrades: "closed-trades",
      closingPriceMethodologies: "closing-price-methodologies",
      commodities: "commodities",
      ccps: "ccps",
      commitments: (ref_id) => `tranche/commitments/${ref_id}`,
      crossTrades: "cross-trades",
      currencies: "currencies",
      derivatives: "securities/derivatives",
      dispatches: "dispatches",
      downloadSubAccountBalance: (code) =>
        `chart-of-accounts/${code}/download_balance`,
      downloadWalletBalance: "download-wallet-balance",
      errorDawaPortfolioMismatch: "error-status/dawa_portfolio_mismatch",
      errorFailedMatchedOrders: "error-status/process_tracking",
      errorInconsistentMatchedTrades: "error-status/trade_request",
      errorNegativeWallet: "error-status/wallet_negative",

      errorStatus: "error-status",
      errorWalletOpRequest: "error-status/wallet_op_request",
      errorWithdrawalRequest: "error-status/withdrawal_request",
      events: "events",
      exportSubAccountBalance: ({ date, code }) =>
        `/chart-of-accounts/${code}/export_balance?date=${date}`,
      exportWalletBalance: ({ date }) => `export-wallet-balance?date=${date}`,
      fees: "fees",
      groups: "groups",
      invoices: "invoices",
      isTaskDone: (id) => `is-task-done/${id}`,
      journalEntry: "journal-entries",
      journalEntryPostings: "journal-entries/postings",
      journalEntryPosting: (entry_id) => `journal-entries/${entry_id}/postings`,
      lastExportId: (type) => `last-export-id?export_type=${type}`,
      liquidations: "liquidations",
      listing: "listings",
      listingDetails: (pk) => `listings/${pk}`,
      listingDocumentDetails: (pk) => `listing-documents/detail/${pk}`,
      listingDocuments: (ref_id) => `listing-documents/${ref_id}`,
      listingProductTypeList: "list-product-type",
      listingDocumentTypeList: "list-document-type",
      listingPartyCategoryList: "list-party-category",
      listings: "security/listings",
      liquidationStatus: "liquidation/status",
      locationsList: "location-list",
      logisticsOfficers: "logistics-officers",
      marginAccount: (cid) => `margin-account/${cid}`,
      marginAccountLogs: (cid) => `margin-account-logs?cid=${cid}`,
      matchedTrades: "matched-orders", // (tid) => `matched-orders/${tid}`,
      notificationList: "notification-list",
      omsProviders: "oms-providers",
      openTrades: "open-trades",
      operationTypes: "operation-types",
      orderTypes: "order-types",
      otcContracts: "otc-contracts",
      otcForwardsContracts: "otc-forwards-contracts",
      otcContractDetail: (contractPk) => `otc-contracts/${contractPk}/view`,
      otcDispatches: (contractPk) =>
        contractPk
          ? `otc-dispatches?contract__pk=${contractPk}&is_active=true`
          : "otc-dispatches?is_active=true",
      otcDispatchesBuyerPickup: (contractPk) =>
        contractPk
          ? `otc-dispatches?contract__pk=${contractPk}&is_buyer_pickup=true`
          : "otc-dispatches?is_buyer_pickup=true",
      otcDispatchesInTransit: (contractPk) =>
        contractPk
          ? `otc-dispatches/in-transit?contract__pk=${contractPk}&is_active=true`
          : "otc-dispatches/in-transit?is_active=true",
      otcDispatchesInactive: (contractPk) =>
        contractPk
          ? `otc-dispatches/in-transit?contract__pk=${contractPk}&is_active=false`
          : "otc-dispatches/in-transit?is_active=false",
      otcDispatchesInTransitDivertLog: (pk) =>
        `otc-dispatches/in-transit/divert-log?initial_dispatch=${pk}`,
      otcDispatchesInTransitTransloadLog: (pk) =>
        `otc-dispatches/in-transit/transload-log?child_pk=${pk}`,
      otcDispatchesConfirm: (pk) => `otc-dispatches/${pk}/confirm-buyer-pickup`,
      otcDispatchesSummary: "otc-dispatches/in-transit-summary",
      parentAccounts: "accounts/parents",
      parentAccountsByType: (type) => `accounts/parents?type=${type}`,
      pendingList: "pending-list",
      permissions: "permissions",
      profitAndLoss: (fiscal_year) => `p&l?fy=${fiscal_year}`,
      requestLoginCode: "request/login_code",
      securities: "securities",
      securityList: "security-list",
      securityPositions: "security-positions",
      sellOrders: "open-sell-trades",
      series: (ref_id) => `series/${ref_id}`,
      seriesDetails: (pk) => `series/detail/${pk}`,
      seriesDocuments: (ref_id) => `series-documents/${ref_id}`,
      seriesDocumentDetails: (pk) => `series-documents/detail/${pk}`,
      storageDepository: (cid) => `client-csd?cid=${cid}`,
      suspenseHistory: "client-suspense-history",
      suspensePool: "client-suspense-pool",
      suspensePositions: "suspense-totals",
      tenantList: "tenants",
      tenants: (cid) => `tenants?client__cid=${cid}`,
      tenantSettings: (id) => `tenant-settings/${id}`,
      tenantWarehouse: "tenants/warehouse",
      tenantWarehousePosition: ({ tenant_id, location_code, item_code }) =>
        `tenants/warehouse?${toURLSearchParams({
          tenant_id,
          location_code,
          item_code,
        })}`,
      tranche: (ref_id) => `tranche/${ref_id}`,
      trancheDetails: (pk) => `tranche/detail/${pk}`,
      trancheDocuments: (ref_id) => `tranche-documents/${ref_id}`,
      trancheDocumentDetails: (pk) => `tranche-documents/detail/${pk}`,
      transactionBanks: "transaction-banks",
      transactionMapping: "trans-account-mappings",
      users: "users",
      walletHistory: (cid, oms_code) =>
        `wallet-history?cid=${cid}&agent_code=${oms_code}`,
      walletStatementFiles: (cid) => `wallet-statement-files?cid=${cid}`,
      warehousePosition: ({ tenant_code, location_code, item_code }) =>
        `warehouse/position?${toURLSearchParams({
          tenant_code,
          location_code,
          item_code,
        })}`,
      warehouseTSD: (tenant_id) => `warehouse/tsd?tenant_id=${tenant_id}`,
      withdrawalRequests: "withdrawal-requests",
    },
    mutations: {
      activateOms: (id) => `oms-providers/${id}/activate`,
      addBankInformation: (cid) => `bank-info/${cid}`,
      addBoardFee: (code) => `boards/${code}/add_fee`,
      attachBoardFees: (code) => `boards/${code}/attach-fees`,
      addSecurityFee: (code) => `securities/${code}/add_fee`,
      addSecurityPrice: (code) => `securities/${code}/set_price`,
      attachSecurityFees: (code) => `securities/${code}/attach-fees`,
      archiveNotification: (nid) => `archive-notification/${nid}`,
      attachLogisticsOfficer: (contract_id) =>
        `otc-contracts/${contract_id}/attach_logistic_officer`,
      attachTenantToClient: (cid) => `attach-to-tenant/${cid}`,
      attachTenantToDispatch: (id) => `dispatches/${id}/attach-tenant`,
      approveCashAdvance: `approve/cash-advance`,
      approveDispatchRequest: `dispatches/approve`,
      approveListing: (pk) => `listings/approve/${pk}`,
      approveSeries: (pk) => `series/approve/${pk}`,
      approveTranche: (pk) => `tranche/update-status/${pk}`,
      approveOtcDispatchesBuyerPickup: `otc-dispatches/confirm-buyer-pickup`,
      approveWithdrawalRequest: "withdrawal-requests/approve",
      approveWarehouseReceipt: "cia-to-csd-request-approval",
      cancelOtcContract: (contract_id) => `otc-contracts/${contract_id}/cancel`,
      createFixedIncomePoint: (
        type: "product-type" | "party-category" | "document-type"
      ) => `create-${type}`,
      convertDawaToSpot: "convert-security/dawa-to-spot",
      convertSpotToDawa: "convert-security/spot-to-dawa",
      completeContract: (pk) =>
        `otc-contracts/${pk}/mark-contract-as-completed`,
      createAccount: "accounts/create",
      createBoard: "boards/create",
      createCashAdvance: (cid) => `cash-advance/${cid}`,
      createDirectDelivery: "direct-delivery/create",
      createDispatch: (code) => `dispatches/${code}/create`,
      createEvent: "events/create",
      createFee: "fees/create",
      createGroup: "groups/create",
      createJournalEntry: "add-journal-entry",
      createListing: "security/listings/create",
      createOms: "oms-providers",
      createSecurity: "securities/create",
      createTransactionBank: "transaction-banks/create",
      createTransactionMapping: "trans-account-mappings/create",
      createUser: "users/create",
      createWarehouseDelivery: "warehouse-delivery/create",
      createWithdrawalRequest: "withdrawal-requests/create",
      createMarginWalletWithdrawalRequest: "margin-account-withdrawal",
      creditFromSuspense: `credit-from-suspense`,
      deactivateSecurity: (code) => `securities/${code}/deactivate`,
      debitToSuspense: `debit-to-suspense`,
      deleteBoardFee: (pk) => `boards/fees/${pk}/delete`,
      deleteSecurityFee: (pk) => `securities/fees/${pk}/delete`,
      deleteFee: (pk) => `fees/${pk}/delete`,
      delistSecurity: (pk) => `security/listings/${pk}/delist`,
      divertOtcDispatchesInTransit: (pk) =>
        `otc-dispatches/in-transit/${pk}/divert`,
      exportSubAccountBalance: ({ date, code }) =>
        `/chart-of-accounts/${code}/export_balance?date=${date}`,
      exportWalletBalance: ({ date }) => `export-wallet-balance?date=${date}`,
      downloadMatchedOrderContractNote: ({ matched_id }) =>
        `matched-orders/${matched_id}/download-contract-note`,
      forgotPassword: "users/forget-password",
      fundClient: (cid) => `client-fund/${cid}`,
      lienUnlien: "client-portfolio/lien-unlien",
      fundMarginWallet: (cid) => `margin-account/fund/${cid}`,
      liquidate: (security) => `liquidate/${security}`,
      lockAccount: "account-lock",
      markAllNotifications: "mark-all-notifications",
      markNotificationAsRead: (nid) => `mark-notification/${nid}`,
      omsProvider: (id) => `oms-providers/${id}`,
      resendMatchedOrderContractNote: ({ matched_id }) =>
        `matched-orders/${matched_id}/resend-contract-note`,
      requestListingInformation: (pk) => `request-listing-info/${pk}`,
      requestSeriesInformation: (pk) => `request-series-info/${pk}`,
      requestTrancheInformation: (pk) => `request-tranche-info/${pk}`,
      rejectListing: (pk) => `listings/reject/${pk}`,
      rejectSeries: (pk) => `series/reject/${pk}`,
      rejectTranche: (pk) => `tranche/reject/${pk}`,
      rejectWithdrawalRequest: "withdrawal-requests/reject",
      resendOtp: "resend-otp",
      revertContractDelivery: (pk) => `otc-dispatches/${pk}/revert`,
      revertFund: ({ cid, id }) => `/client-fund/revert/${cid}/${id}`,
      sendWalletStatement: "wallet-statement-export",
      sendWalletStatementEmail: "send-wallet-statement-email",
      setPassword: "new-password",
      suspendClient: (cid) => `client-suspend/${cid}`,
      suspendClientNotification: (cid) => `client-suspend/${cid}/notify_oms`,
      suspendOms: (id) => `oms-providers/${id}/suspend`,
      suspendUser: (pk) => `users/suspend/${pk}`,
      transloadDispatch: (id) => `otc-dispatches/in-transit/${id}/transload`,
      triggerSettlement: (pk) =>
        `otc-forwards-contracts/${pk}/manual-settlement`,
      updateAccount: (code) => `accounts/${code}/update`,
      updateBankInformation: (cid) => `bank-info/${cid}/update`,
      updateBoard: (code) => `boards/${code}/update`,
      updateBoardFee: (pk) => `boards/fees/${pk}/update`,
      updateCashCover: (cid) => `cash-cover/${cid}/update`,
      updateClientPermissions: (cid) => `client-permissions/${cid}`,
      updateContract: (pk) => `otc-contracts/${pk}/update`,
      updateFee: (pk) => `fees/${pk}/update`,
      updateFixedIncomePoint: (
        type: "product-type" | "party-category" | "document-type",
        pk
      ) => `update-${type}/${pk}`,
      updateGroup: (pk) => `groups/update/${pk}`,
      updateListing: (pk) => `security/listings/${pk}/update`,
      updateSecurity: (code) => `securities/${code}/update`,
      updateStockCover: (cid) => `update-stock-cover/${cid}`,
      updateTenantSettings: (id) => `tenant-settings/${id}`,
      updateTransactionBank: (pk) => `transaction-banks/${pk}/update`,
      updateUser: (pk) => `users/update/${pk}`,
      verifyOtp: "verify-otp",
    },
  },
  keys: {
    accessibleBoards: "accessible-board",
    activityStream: "activity-stream",
    balanceSheet: (fiscal_year) => ["balance-sheet", fiscal_year],
    bankList: "bank-list",
    boardList: "board-list",
    boards: "boards",
    buyOrders: "buy-trades",
    cashAdvance: "cash-advance",
    cashAdvancePosting: (id) => ["cash-advance", id],
    cashCoverList: (props: CashCoverProps) => [
      "client-wallet",
      "cash-cover",
      "list",
      props,
    ],
    cashCoverAggregate: (props: CashCoverProps) => [
      "client-wallet",
      "cash-cover",
      "aggregate",
      props,
    ],
    cashCoverLog: (cid) => ["cash-cover-log", cid],
    chartOfAccounts: "chart-of-accounts",
    chartOfAccountsDetails: (cid) => ["accounts", "transaction-items", cid],
    chartOfAccountsHistory: (cid) => ["chart-of-accounts", cid],
    chartOfAccountsSubAccount: (cid) => [
      "chart-of-accounts",
      "sub-account",
      cid,
    ],
    checkAccountLock: "check-account-lock",
    ciaToCsdRequestList: ["CIA", "CSD", "list"],
    clientBankInfo: (cid) => ["client-bank-information", cid],
    clientCashAdvance: (cid) => ["cash-advance", cid],
    clientDetail: (cid) => ["client-detail", cid],
    clientExport: ["client-export"],
    clientFunded: (cid) => ["client-funded", cid],
    clientFundingLog: "funding-list",
    clientFundList: (cid, oms_code) => ["fund-log", cid, oms_code],
    clientPermissions: (cid) => ["client-permission", cid],
    clientPortfolio: (cid) => ["client-portfolio", cid],
    clientPortfolioHistory: (cid) => ["client-portfolio-history", cid],
    clientPositions: "client-positions",
    clientSuspended: (cid) => ["client-suspend", cid],
    clientWallet: (cid) => ["client-wallet", cid],
    closedTrades: "closed-trades",
    closingPriceMethodologies: "closing-price-methodologies",
    commodities: "commodities",
    ccps: "ccps",
    commitments: (ref_id) => ["tranches", "commitments", ref_id],
    createTransactionMapping: ["trans-account-mapping", "create"],
    crossTrades: "cross-trades",
    currencies: "currencies",
    dawaToSpot: "dawa-to-spot",
    deliveryLogs: (contract_id) => ["otc-contracts", contract_id],
    delistSecurity: (pk) => ["securities", pk, "delist"],
    derivatives: ["securities", "derivatives"],
    dispatches: "dispatches",
    errorDawaPortfolioMismatch: ["error-status", "dawa_portfolio_mismatch"],
    errorFailedMatchedOrders: ["error-status", "process_tracking"],
    errorInconsistentMatchedTrades: ["error-status", "trade_request"],
    errorNegativeWallet: ["error-status", "wallet_negative"],
    errorStatus: ["error-status"],
    errorWalletOpRequest: ["error-status", "wallet_op_request"],
    errorWithdrawalRequest: ["error-status", "withdrawal_request"],
    events: "events",
    exportWalletBalance: ["export", "wallet"],
    exportSubAccountBalance: (code) => ["export", "sub-account", code],
    fees: ["fees"],
    fixedIncomeSettings: (...type) => ["fixed_income", "listing"].concat(type),
    groups: "groups",
    invoices: "invoices",
    journalEntry: "journal-entries",
    journalEntryPosting: (entry_id) => ["journal-entries", entry_id],
    journalEntryPostings: ["journal-entries", "postings"],
    lastDownloadedExport: (type, date) => [
      "export",
      "balance",
      type,
      "download",
      date,
    ],
    lastExportId: (type) => ["export", "balance", type, "last-id"],
    lienUnlien: ["client-portfolio", "lien-unlien"],
    liquidations: ["liquidations"],
    listing: ["listing"],
    listingDetails: (pk) => ["listing", "details", pk],
    listingDocumentDetails: (ref_id) => [
      "listing",
      "documents",
      "details",
      ref_id,
    ],
    listingDocuments: (pk) => ["listing", "documents", pk],
    listings: ["securities", "listings"],
    liquidate: (security) => ["liquidate", security],
    liquidationStatus: ["liquidation", "status"],
    locationsList: "location-list",
    logisticsOfficers: "logistics-officers",
    marginAccount: (cid) => ["margin", cid],
    marginAccountLogs: (cid) => ["margin", "account", "logs", cid],
    matchedTrades: "matched-orders",
    notificationList: ["in-app", "notifications"],
    omsProviders: "oms-providers",
    openTrades: "open-trades",
    operationTypes: "operation-types",
    orderTypes: "order-types",
    otcContracts: "otc-contracts",
    otcForwardsContracts: ["otc-forwards-contracts"],
    otcContractDetail: (contractPk) => ["otc-contracts", contractPk],
    otcDispatches: (contractPk) =>
      contractPk
        ? ["otc-dispatches", "confirmed", contractPk]
        : ["otc-dispatches", "confirmed"],
    otcDispatchesBuyerPickup: (contractPk) =>
      contractPk
        ? ["otc-dispatches", "buyer-pickup", contractPk]
        : ["otc-dispatches", "buyer-pickup"],
    otcDispatchesInactive: (contractPk) =>
      contractPk
        ? ["otc-dispatches", "inactive", contractPk]
        : ["otc-dispatches", "inactive"],
    otcDispatchesInTransit: (contractPk) =>
      contractPk
        ? ["otc-dispatches", "in-transit", contractPk]
        : ["otc-dispatches", "in-transit"],
    otcDispatchesSummary: ["otc-dispatches", "in-transit", "summary"],
    otcDispatchesInTransitDivertLog: (pk) => [
      "otc-dispatches",
      "in-transit",
      "divert-log",
      pk,
    ],
    otcDispatchesInTransitTransloadLog: (pk) => [
      "otc-dispatches",
      "in-transit",
      "transload-log",
      pk,
    ],
    otcDispatchesConfirm: (pk) => ["otc-dispatches", "confirm-buyer", pk],
    parentAccounts: ["chart-of-accounts", "parent"],
    parentAccountsByType: (type) => ["chart-of-accounts", "parent", type],
    pendingList: ["pending-list"],
    permissions: "permissions",
    profitAndLoss: (fiscal_year) => ["profit-and-loss", fiscal_year],
    revertFund: ({ cid, id }: { cid; id: number }) => [cid, id],
    securities: "securities",
    securityList: "security-list",
    securityPositions: ["security", "positions"],
    sellOrders: "sell-trades",
    series: (ref_id) => ["series", ref_id],
    seriesDetails: (pk) => ["series", "details", pk],
    seriesDocumentDetails: (ref_id) => [
      "series",
      "documents",
      "details",
      ref_id,
    ],
    seriesDocuments: (pk) => ["series", "documents", pk],
    setPassword: "new-password",
    spotToDawa: "spot-to-dawa",
    storageDepository: (cid) => ["storage-depository", cid],
    suspendClientNotification: (cid) => ["client-suspend", "notify-oms", cid],
    suspenseHistory: ["suspense", "history"],
    suspensePool: ["suspense", "pool"],
    suspensePositions: ["suspense", "positions"],
    tenantList: ["tenant", "list"],
    tenants: (cid) => ["tenant", "list", cid],
    tenantSettings: (id) => ["tenant", "settings", id],
    tenantWarehouse: ["tenants", "warehouse"],
    tenantWarehousePosition: ({ location_code, item_code, tenant_id }) => [
      "tenants",
      "warehouse",
      location_code,
      item_code,
      tenant_id,
    ],
    tranche: (ref_id) => ["tranche", ref_id],
    trancheDetails: (pk) => ["tranche", "details", pk],
    trancheDocumentDetails: (ref_id) => [
      "tranche",
      "documents",
      "details",
      ref_id,
    ],
    trancheDocuments: (pk) => ["tranche", "documents", pk],
    transactionBanks: "transaction-banks",
    transactionMapping: "transaction-mappings",
    updateTransactionMapping: ["trans-account-mapping", "update"],
    users: "users",
    walletHistory: (cid, oms_code) => ["wallet-history", cid, oms_code],
    walletStatementFiles: (cid) => ["wallet-statement", "files", cid],
    warehousePosition: ({ location_code, item_code, tenant_code }) => [
      "warehouse",
      "position",
      location_code,
      item_code,
      tenant_code,
    ],
    warehouseTSD: (tenant_id) => ["tenant", "warehouse", "tsd", tenant_id],
    withdrawalRequests: "withdrawal-requests",
  },
};

// VOLUME PER UNIT OPTIONS

export const volumePerUnitOptions = ["1", "10", "100", "1000"];

export const orderTypes = {
  buy: "Buy",
  sell: "Sell",
};

export const orderTypeOptions = ["Sell", "Buy"];

export const feeTypeOptions = [
  { label: "VAT", value: "vat" },
  { label: "Percentage", value: "percentage" },
  { label: "Fixed", value: "fixed" },
];

export const feeStructureOptions = [
  { label: "Unit", value: "unit" },
  // { label: "Trade", value: "trade" },
];

export const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.sssZ";
export const CURRENCY_OPTIONS = [
  {
    label: "NGN (Naira)",
    value: "NGN",
  },
  {
    label: "KES (Kenyan Shillings)",
    value: "KES",
  },
];

export const NOTIFICATION_PAGES = {
  Dispatch_Request: "/market-management/dispatch",
  OTC_Dispatch_Log: "/market-management/otc-contracts/delivery-log",
  OTC_Contract_Matched: "/market-management/otc-contracts",
  Cash_Advance: "/market-management/cash-advance",
  Invoice_Created: "/market-management/invoice",
  manual_cia_csd: "/market-management/warehouse-receipt",
  scheduled_cia_csd: "/market-management/warehouse-receipt",
  e_warehouse_receipt: "/market-management/warehouse-receipt",
  Series_Update_Request: "/market-access/securities/fixed-income",
  Series_Submission: "/market-access/securities/fixed-income",
  Listing_Update_Request: "/market-access/securities/fixed-income",
  Listing_Request: "/market-access/securities/fixed-income",
};
