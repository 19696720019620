import { ClientWalletDataType, CurrencyListDataType } from "@/sample/queries";
import { useMemo } from "react";

type CurrencyType = Record<
  string,
  {
    label: string;
    value: string;
  }
>;

interface UseGetCurrenciesFromClientWallet {
  clientWalletData: ClientWalletDataType[];
  clientWalletLoading: boolean;
  value?: keyof CurrencyListDataType;
}

export function useGetCurrenciesFromClientWallet({
  clientWalletData = [],
  clientWalletLoading,
  value = "code",
}: UseGetCurrenciesFromClientWallet) {
  const currencyOptions = useMemo(() => {
    const currencies =
      clientWalletData?.reduce((uniqueCurrencies, { currency }) => {
        const { code, sign, name } = currency;
        if (!uniqueCurrencies[code]) {
          uniqueCurrencies[code] = {
            label: `${name} (${sign})`,
            value: currency[value],
          };
        }
        return uniqueCurrencies;
      }, {} as CurrencyType) ?? {};
    return Object.values(currencies);
  }, [clientWalletLoading]);

  return {
    currencyOptions,
  };
}
