import { deleteCookie } from "cookies-next";
import { useRouter } from "next/router";
import { useCallback } from "react";

export function useLogout() {
  const { push, isReady } = useRouter();

  const logout = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    deleteCookie("ecn.client.auth.token");
    push("/");
  }, [isReady]);

  return logout;
}
